import { useTranslation } from "react-i18next";
import { Button } from "../components/utility/Button";

export function DirectCallToAction() {
  const { t } = useTranslation();

  return (
    <div
      className=""
      style={{
        backgroundImage: `url(/assets/direct-call-to-action-background.png)`,
        backgroundSize: "cover",
      }}
    >
      <div className="flex flex-col items-center justify-center w-full gap-16 p-4 py-44 backdrop-brightness-50">
        <h1 className="text-4xl text-center text-white capitalize">
          {t("homepage.call_to_action.title")}
        </h1>
        <Button>{t("direct_call_to_action.button")}</Button>
      </div>
    </div>
  );
}
