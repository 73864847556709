import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuthentication } from "../../authentication";
import { Loader } from "../../components/utility/Loader";
import { useUser } from "../../authentication/user";
import DownIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Card, Dropdown, Menu } from "antd";

import SignoutIcon from "@mui/icons-material/ExitToAppRounded";
import AccountIcon from "@mui/icons-material/AccountBoxRounded";
import ServersIcon from "@mui/icons-material/DnsRounded";
import InvoicesIcon from "@mui/icons-material/ReceiptRounded";
import { MenuUserThumbnail } from "../../account/widgets/MenuUserThumbnail";

export function NavbarUserThumbnail() {
  const { user } = useUser();
  const { signout } = useAuthentication();
  const { t } = useTranslation();

  const menu = [
    {
      label: t("navbar.account"),
      icon: <AccountIcon />,
      link: "/account",
    },
    {
      label: t("navbar.servers"),
      icon: <ServersIcon />,
      link: "/account/servers",
    },
    {
      label: t("navbar.invoices"),
      icon: <InvoicesIcon />,
      link: "/account/invoices",
    },
    {
      label: t("navbar.signout"),
      icon: <SignoutIcon />,
      onClick: signout,
    },
  ];

  return (
    <Dropdown
      placement="bottomRight"
      trigger="click"
      overlay={
        <div className="py-2 space-y-4 text-white rounded bg-background">
          <div className="px-4">
            <MenuUserThumbnail />
          </div>
          <div className="flex flex-col gap-2">
            {menu.map((item) => {
              const _item = (
                <div
                  key={item.label}
                  className="flex items-center gap-2 px-4 py-2 cursor-pointer"
                  onClick={item.onClick}
                >
                  {item.icon}
                  <span className="flex-grow text-lg">{item.label}</span>
                </div>
              );
              if (item.link) {
                return (
                  <Link
                    className="text-gray-900 hover:text-light-1 text-inherit"
                    to={item.link}
                    key={item.label}
                  >
                    {_item}
                  </Link>
                );
              }
              return _item;
            })}
          </div>
        </div>
      }
    >
      <div className="flex items-center text-white">
        <span className="truncate">{user?.name ?? t("unnamed")}</span>
        <DownIcon />
      </div>
    </Dropdown>
  );
}

export function NavbarAuthentication() {
  const { t } = useTranslation();
  const { is_authenticated } = useAuthentication();

  switch (is_authenticated) {
    case undefined:
      return <Loader />;
    case true:
      return <NavbarUserThumbnail />;
    case false:
      return (
        <Link className="text-white capitalize" to="/authentication">
          {t("homepage.signin")}
        </Link>
      );
    default:
      return null;
  }
}
