import { Button, Card, Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SettingOutlined } from "@ant-design/icons";
import { IF } from "../adhoc/IF";
import { UpdatePackage } from "../../account/packages/widgets/UpdatePackage";
import { DeletePackage } from "../../account/packages/widgets/DeletePackage";

function Entitlement({ entitlement }) {
  return (
    <div className="flex items-center gap-2 text-lg text-left text-white">
      <span className="inline-block w-3 h-3 rounded-full bg-purple"></span>
      <span>{entitlement}</span>
    </div>
  );
}

export function GamePackage({
  game,
  plan,
  action,
  startingFrom,
  entitlements,
  readOnly,
}) {
  const { t } = useTranslation();

  return (
    <div
      className={`relative flex outline outline-light-1 group flex-col w-full duration-200 mx-auto rounded-md shadow cursor-pointer aspect-[0.75] max-w-sm bg-background bg-primary shadow-purple-900 hover:shadow-purple-500`}
    >
      <IF that={!readOnly}>
        <Dropdown.Button
          placement="bottomRight"
          className="absolute z-10 top-2 right-2"
          overlay={
            <Card size="small">
              <div className="flex flex-col gap-2">
                <UpdatePackage package={plan} />
                <DeletePackage package={plan} />
              </div>
            </Card>
          }
          ic
          icon={<SettingOutlined />}
        ></Dropdown.Button>
      </IF>
      <div
        className="flex-grow h-64 rounded-md"
        style={{
          backgroundImage: `url(${game?.artwork})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex items-center justify-center w-full h-full duration-300 rounded-md backdrop-brightness-75 group-hover:backdrop-brightness-100"></div>
      </div>
      <div className="flex flex-col gap-4 p-6 text-center">
        <h1 className="m-0 text-2xl text-white capitalize">{plan.name}</h1>

        <div className="w-full px-4 rounded bg-light-1 h-[3px]" />

        <div className="flex items-center justify-center gap-1 text-3xl text-white">
          {startingFrom && (
            <span className="mr-1 text-sm text-gray-400">
              {t("plan.starting_from")}
            </span>
          )}
          <span>$</span>
          <span>{plan.price?.toFixed(2)}</span>
        </div>
        <div className="space-y-4">
          {plan.slots && (
            <Entitlement entitlement={`${plan.slots} ${t("plan.slots")}`} />
          )}
          {plan.ram && (
            <Entitlement entitlement={`${plan.ram} ${t("plan.ram")}`} />
          )}
          {plan.cycle && (
            <Entitlement entitlement={`${plan.cycle} ${t("plan.cycle")}`} />
          )}
          {entitlements?.map((entitlement, index) => {
            return <Entitlement entitlement={entitlement} key={index} />;
          })}
        </div>
        {action && (
          <Link to={`/checkout?game=${game._id}&package=${plan._id}`}>
            <Button
              ghost
              className="w-full h-auto py-2 text-base font-bold text-white capitalize rounded hover:border-light-1"
            >
              {action}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
}
