import { useTranslation } from "react-i18next";
import OrderDetails from "../detail";
import { Button } from "antd";

const OrderConfirmation = ({ subscription, game, onNext }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center w-full gap-4 p-16 text-white shadow shadow-purple-400">
      <p className="text-4xl capitalize">{t("checkout.thankyou")}</p>
      <p className="text-xl capitalize">{t("checkout.order_received")}</p>
      <div className="w-full max-w-xl">
        <p className="capitalize">{t("checkout.order_details")}</p>
        <OrderDetails game={game} subscription={subscription} />
      </div>
      <div className="w-full max-w-xl border border-light-1"></div>
      <div className="flex justify-between w-full max-w-xl">
        <span className="text-xl capitalize">{t("checkout.price")}</span>
        <span className="text-2xl text-purple-500">
          {subscription.price ?? 4.2} $
        </span>
      </div>
      <div className="px-16 text-right">
        <Button
          ghost
          onClick={() => onNext()}
          className="h-auto py-2 font-bold text-white capitalize border-none rounded text-md bg-gradient-to-br from-violet-600 via-violet-700 to-violet-900 shadow-purple-500"
        >
          {t("checkout.next")}
        </Button>
      </div>
    </div>
  );
};

export default OrderConfirmation;
