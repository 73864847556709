import { Button as AntdButton } from "antd";
import { Link } from "react-router-dom";
import { IF } from "../adhoc/IF";

export function Button({
  variant,
  size,
  children,
  onClick,
  ghost,
  href,
  block,
  icon,
  className,
  disabled,
}) {
  const b = (
    <AntdButton
      disabled={disabled}
      type={variant}
      size={size}
      onClick={onClick}
      ghost={ghost}
      block={block}
      className={`flex gap-2 w-screen h-auto max-w-xs py-2 font-bold text-white capitalize border-none rounded text-md bg-gradient-to-br from-violet-600 via-violet-700 to-violet-900 shadow-purple-500 ${className}`}
    >
      <IF that={icon}>{icon}</IF>
      <IF that={children}>
        <div className="flex-grow">{children}</div>
      </IF>
    </AntdButton>
  );
  if (href) return <Link to={href}>{b}</Link>;
  return b;
}
