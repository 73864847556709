import { Button } from "antd";
import { useTranslation } from "react-i18next";

export function StepsCallToAction() {
  const { t } = useTranslation();

  const step = [
    {
      title: `${t("step.title1")}`,
      icon: "/assets/one.svg",
    },
    {
      title: `${t("step.title2")}`,
      icon: "/assets/two.svg",
    },
    {
      title: `${t("step.title3")}`,
      icon: "/assets/three.svg",
    },
  ];
  return (
    <div className="container p-4 mx-auto">
      <div className="flex flex-col items-center px-4 py-16 space-y-24 text-center rounded shadow shadow-purple-500">
        <h1 className="max-w-sm text-4xl text-white capitalize">
          {t("stepscta.title")}
        </h1>
        <div className="flex flex-col gap-16 lg:flex-row justify-left">
          {step.map((step) => ( 
            <div
              className="flex items-center justify-center gap-8"
              key={step.title}
            >
              <img src={step.icon} />
              <div className="flex flex-col">
                <h1 className="pb-4 text-2xl text-white capitalize">
                  {step.title}
                </h1>
                <div
                  className="w-full rounded bg-light-1 purple-shadow"
                  style={{ height: "2px" }}
                />
              </div>
            </div>
          ))}
        </div>
        <Button
          ghost
          className="w-screen h-auto max-w-xs py-2 font-bold text-white capitalize border rounded text-md button-background border-light-2"
        >
          {t("stepscta.button")}
        </Button>
      </div>
    </div>
  );
}
