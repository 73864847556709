import { Card, Spin } from "antd";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

const PaymentForm = ({ onNext, subscriptionId }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [clicked, setClicked] = useState(false);

  const param = {
    package: "adil calling",
    package_id: query.get("package"),
    game_id: query.get("game"),
    subscriptionId: subscriptionId,
  };

  const fetchData = async () => {
    try {
      const response = await axios.post("/tappay/charge", param);
      console.log("Response::", response.data);
      const transaction = response.data.transaction;
      console.log("transaction", transaction.url);
      //window.open(transaction.url);
      return response.data; // Return only the data if needed
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const { data, isLoading, error } = useQuery("transactions", fetchData, {
    enabled: clicked, // Only fetch data when clicked is true
    onSuccess: (data) => {
      const transaction = data.transaction;
      console.log("transaction", transaction.url);
      window.open(transaction.url);
      setClicked(false); // Reset clicked to prevent re-fetching
    },
  });

  const handleClick = () => setClicked(true);

  return (
    <div className="px-4 py-10 shadow shadow-purple-400">
      <h1 className="text-xl text-center text-white capitalize">
        {t("checkout.choose_payment_option")}
      </h1>
      <Card>
        <div className="text-center">
          <button
            onClick={handleClick}
            disabled={isLoading}
            className="relative"
          >
            {isLoading ? <Spin size="small" /> : t("checkout.pay")}
          </button>
        </div>
      </Card>
    </div>
  );
};

export default PaymentForm;
